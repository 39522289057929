.clientlist-title {
    font-size: 20px;
    color: #333;
    width: 250px;
    font-weight: 800;
}

.Add-Client-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    /* Optional: adds space below the container */
}

.add-client-button {
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #f4f4f5;
    font-weight: bold;
    width: 150px;
}

.global-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Space between label and input */
    margin-bottom: 1rem;
}

.filter-label {
    font-weight: bold;
    margin: 0;
    /* Remove default margin */
}

.filter-input {
    padding: 7px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
    width: 400px;
    flex: 1;
    /* Allow input to expand if needed */
}

.filter-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}



.table-container {
    overflow-x: auto;
    margin-top: -20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f4f4f448;
    padding: 10px;
}

.client-table {
    padding: 10px;
    background-color: white;
    margin-top: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {

    cursor: pointer;
}


.pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    /* Align to start */
    gap: 5px;
    /* Adjust gap as needed */
}

/* Pagination buttons and select dropdown */
.pagination span,
.pagination select {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Ensure consistent box-sizing */
    line-height: 1;
    font-size: 16px;
    cursor: pointer;
}

/* Ensure no extra space */
.pagination span,
.pagination select {
    flex: 0 0 auto;
    /* Prevent flex items from growing/shrinking */
}

/* Disabled state styling */
.pagination span[disabled] {
    color: #ccc;
    cursor: not-allowed;
}

/* Select dropdown styling */
.pagination select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 80px;
    /* Adjust width as needed */
}



.actions-cell {
    display: flex;
    gap: 10px;
}

.actions-cell a,
.actions-cell button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
}

.actions-cell button {
    color: rgb(10, 1, 1);
}


.actions-cell button:hover,
.actions-cell button:focus {
    background: none;
    outline: none;
    color: #1515e4;
}


/* Responsive styles */
@media screen and (max-width: 768px) {

    .Add-Client-button-container {
        flex-direction: column;
        /* Stack the elements vertically on smaller screens */
        align-items: flex-start;
    }

    .add-client-button {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 55px;
    }

    .clientlist-title {
       margin-left: 10px !important;
    }
    
    .client-table {
        padding: 10px !important;
        background-color: #ffffff40 !important;
        margin-top: 10px !important;
    }
    

    .filter-label {
        display: none;
    }

    .global-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        /* Space between label and input */
        margin-bottom: 1rem;
        width: 100%;
    }


    .pagination {
        flex-direction: row !important;     
        flex-wrap: nowrap !important;    
        justify-content: flex-start !important;  
    }

    .pagination span{
        margin-top: 10px !important;
    }

    .pagination select {      
        max-width: 80px !important;
        background-color: #ffffff40 !important;
    }
}