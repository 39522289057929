.property-form-container {   
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f4f4f4;
    padding: 10px;
    max-width: 100%;
    box-sizing: border-box;
}

.addform-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.addform-title {
    font-size: 18px;
    color: #333;
    font-weight: 800;
    margin-bottom: 10px;
}

.header-cancel-button {
    background: none; 
    border: none; 
    cursor: pointer;   
    color: #f44336; 
    font-size: 20px; 
}

.header-cancel-button:hover,
.header-cancel-button:focus {
    background: none;
    outline: none;
}

.form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    flex-wrap: wrap; /* Allow form rows to wrap on smaller screens */
    gap: 20px;
}

.form-group {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width for better responsiveness */
    display: flex;
    flex-direction: column;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
}

.button-container {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;    
}

.submit-button,
.cancel-button {
    padding: 10px 12px;   
    font-size: 14px;  
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Make buttons full-width on smaller screens */
    max-width: 120px; /* Set a maximum width */
}

.submit-button {
    background-color: #4CAF50;   
    color: white;
}

.cancel-button {
    background-color: #f44336;  
    color: white;
}

.radio-group {
    display: flex;
    flex-wrap: wrap; /* Allow radio buttons to wrap on smaller screens */
    gap: 1rem;
}

.radio-group label {
    display: flex;
    align-items: center;
}

.radio-group input[type="radio"] {
    margin-right: 0.5rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .addform-title {
        font-size: 16px;
    }

    .header-cancel-button {
        font-size: 18px;
    }

    .form-row {
        flex-direction: column; /* Stack form groups vertically */
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .submit-button,
    .cancel-button {
        width: 100%;
        max-width: none; /* Remove max-width on small screens */
    }
}

@media (max-width: 480px) {
    .addform-title {
        font-size: 14px;
    }

    .header-cancel-button {
        font-size: 16px;
    }

    .form-group {
        min-width: auto; /* Remove min-width on very small screens */
    }

    .submit-button,
    .cancel-button {
        padding: 8px 10px; /* Adjust padding for smaller screens */
        font-size: 12px;
    }
}
