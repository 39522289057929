.dialogbox-container{
    border: 1px solid #ccc;
    border-radius: 20px;
}

.dialog-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    background-color: #cec8c8;   
  }
  
  .dialog-content {
    margin-top: 20px;
    padding: 16px;
    
  }
  
  .dialog-content-text {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .dialog-actions {
    padding: 8px 16px;
    justify-content: space-between;
  }
  
  .dialog-button {
    color: #007bff;
  }
  
  .dialog-button-confirm {
    color: #ff0000;
    font-weight: bold;
  }
  