html, body {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    margin: 0;
    display: contents;
}

h1, h2, h3, h4, h5, h6, p, a, span, div ,input,select,button,table,td,th{
    font-family: 'Open Sans', sans-serif;
  }
/* Toastify Custom Styles */
.Toastify__toast-container {
    top: 20px; /* Adjust the top positioning */
    right: 20px; /* Adjust the right positioning */
    z-index: 1000;
}

.Toastify__toast--success,
.Toastify__toast--error {
    background-color: #28a745; /* Default to green for success */
    color: #1b0303; /* White text color */
    padding: 10px 15px; /* Adjust padding as needed */
    border-radius: 5px; /* Optional: adds rounded corners */
    display: flex;
    align-items: center;
    max-width: 400px; /* Adjust the max width to ensure visibility */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    position: relative; /* For positioning the close button */
}

.Toastify__toast--error {
    background-color: #e9e7e7; /* Red background for error */
}

.Toastify__toast-body {
    flex: 1; /* Allows text to take up available space */
    margin-right: 10px; /* Space between text and close button */
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides overflow text */
    text-overflow: ellipsis; /* Adds ellipsis (...) to overflowed text */
}

.Toastify__close-button {
    cursor: pointer; /* Changes cursor to pointer on hover */
    background: transparent; /* Transparent background for the button */
    border: none; /* Removes default border */
    font-size: 16px; /* Size of the cross mark */
    color: #070101; /* Color of the cross mark */
    position: absolute; /* Position it inside the toast */
    top: 10%; /* Center vertically */
    left: 40%; /* Space from the right edge */  
}

.Toastify__close-button:hover {
    color: #ff0000; /* Change color of the cross mark on hover */
    background: transparent; /* Ensure background remains transparent */
}

.Toastify__toast--success .Toastify__toast-body,
.Toastify__toast--error .Toastify__toast-body {
    font-size: 16px; /* Adjust font size as needed */
}
