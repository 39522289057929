/* General Styles */
body {
    margin: 0;
}

.dashboard-container {
    display: flex;
    height: 100vh;
    overflow: hidden; /* Ensure no overflow issues */
}

.sidebar {
    width: 210px;
    background-color: #333;
    color: #fff;
    transition: width 0.3s;
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
}

.sidebar.closed {
    width: 55px;
}

.sidebar-header {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
}

.sidebar-logo {
    font-size: 18px;
}

.sidebar-logo.hide {
    display: none;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 15px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.sidebar-item:hover {
    background-color: #444;
}

.sidebar-item.active {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    margin: 5px;
}

.menu-text {
    margin-left: 10px;
}

.menu-text.hide {
    display: none;
}

.main-content {
    margin-left: 210px;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s, width 0.3s;
    width: calc(100% - 210px);
    overflow: hidden; /* Prevent overflow issues */
}

.main-content.closed {
    margin-left: 55px;
    width: calc(100% - 55px);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f4f4f4;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 210px;
    width: calc(100% - 210px); /* Ensure header spans the remaining width */
    z-index: 1;
    transition: left 0.3s, width 0.3s;
}

.header.closed {
    left: 55px;
    width: calc(100% - 55px);
}

.sidebar-toggle, .logout-button {
    background: none;
    border: none;
    color: #333;
    font-size: 24px;
    cursor: pointer;
    outline: none;
}

.sidebar-toggle:hover, .logout-button:hover {
    background: none;
}

.content {
    flex: 1;
    margin-top: 60px; /* Account for the fixed header height */
    overflow-y: auto; /* Allow vertical scrolling */
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.header-logo img {
    width: 500px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  }

/* Mobile responsive styles */
@media (max-width: 768px) {
    .sidebar{
        display: none; 
    }  
    
    .header-logo img {
        width: 80%; /* Adjust as needed */
        height: auto; /* Maintain aspect ratio */
        margin-left: 10%;
      }
    

    .main-content {
        margin-left: 0;
        width: 100%;
    }

    .header {
        left: 0;
        width: 100%;
        padding: 10px;
    }

    .header-title {
        text-align: left;
        margin-left: 10px; /* Add some left margin */
    }

    .content {
        margin-top: 0; /* No need to account for header */
        height: auto;
        overflow-y: auto; /* Ensure scrolling on mobile too */
    }

  
}
