/* Global Styles for Properties Component */
.propertylist-title {
    font-size: 20px;
    color: #333;
    width: 250px;
    font-weight: 800;
}

.Add-Client-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Optional: adds space below the container */
}

.add-property-button {
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #f4f4f5;
    font-weight: bold;
    width: 150px;
}

.global-filter {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between label and input */
    margin-bottom: 1rem;
}

.filter-label {
    font-weight: bold;
    margin: 0; /* Remove default margin */
}

.filter-input {
    padding: 7px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
    width: 400px;
    flex: 1; /* Allow input to expand if needed */
}

.filter-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.table-container {
    overflow-x: auto;
    margin-top: -20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f4f4f4;
    padding: 10px;
}

.property-table {
    padding: 10px;
    background-color: white;
    margin-top: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    cursor: pointer;
}

.pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow pagination controls to wrap */
}

.pagination button {
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.pagination span {
    margin-right: 10px;
}

.actions-cell {
    display: flex;
    gap: 10px;
}

.actions-cell a, .actions-cell button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
}

.actions-cell button {
    color: rgb(10, 1, 1);
}

.actions-cell button:hover,
.actions-cell button:focus {
    background: none;
    outline: none;
    color: #1515e4;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .propertylist-title {
        font-size: 18px;
        width: auto; /* Allow title to use full width */
    }

    .Add-Client-button-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .add-property-button {
        width: 100%;
        max-width: 300px; /* Set max width for buttons */
    }

    .global-filter {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .filter-input {
        width: 100%;
    }

    .table-container {
        padding: 5px;
    }

    .property-table {
        padding: 5px;
    }

    .pagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .pagination button,
    .pagination select {
        margin-bottom: 10px;
    }

    .pagination span {
        margin: 0;
    }
}

@media (max-width: 480px) {
    .propertylist-title {
        font-size: 16px;
    }

    .Add-Client-button-container {
        margin-bottom: 15px;
    }

    .add-property-button {
        font-size: 14px;
        padding: 8px;
        max-width: 250px;
    }

    .filter-input {
        font-size: 14px;
        padding: 5px 10px;
    }

    .table-container {
        padding: 0;
    }

    .property-table {
        padding: 0;
    }

    .pagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .pagination button,
    .pagination select {
        width: 100%;
        max-width: none; /* Remove max-width on very small screens */
    }
}
